import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";
import validations from "@/utils/validations";
import { toastification } from "./modules/toastification.module";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        ...validations,
        loading: false,
        uploading: false,
        justNumbers: "return (event.charCode >= 48 && event.charCode <= 57)",

        length: 0,
        filter: {
            pageIndex: 1,
            pageSize: 30,
            search: "",
        },

        // Global variables
        provinceId: "",
        newsTypeId: "",
        chatId: "",
        chatUserId: "",
        productId: "",
        categoryId: "",
        parentId: null,
        catgId: "",
        clientId: "",
        orderStatus: null,
        complaintType: null,
        duplicated: "",
        fromOffers: "",
        unReadMessages: "",
        isPending: "",

        // lists
        provinces: [
            {
                id: 1,
                name: "بغداد",
            },
            {
                id: 2,
                name: "أربيل",
            },
            {
                id: 3,
                name: "الانبار",
            },
            {
                id: 4,
                name: "بابل",
            },
            {
                id: 5,
                name: "البصره",
            },
            {
                id: 6,
                name: "دهوك",
            },
            {
                id: 7,
                name: "النجف",
            },
            {
                id: 8,
                name: "الديوانيه",
            },
            {
                id: 9,
                name: "ديالى",
            },
            {
                id: 10,
                name: "ذي قار",
            },
            {
                id: 11,
                name: "السليمانيه",
            },
            {
                id: 12,
                name: "صلاح الدين",
            },
            {
                id: 13,
                name: "كركوك",
            },
            {
                id: 14,
                name: "كربلاء",
            },
            {
                id: 15,
                name: "المثنى",
            },
            {
                id: 16,
                name: "ميسان",
            },
            {
                id: 17,
                name: "نينوى",
            },
            {
                id: 18,
                name: "واسط",
            },
        ],
        types: [
            {
                id: 1,
                name: "خبر",
            },
            {
                id: 3,
                name: "مناسبة",
            },
            {
                id: 4,
                name: "اخرى",
            },
        ],
        readyResponses: [],
        chats: [],
        messages: [],
        offers: [],
        categories: [],
        listCategories: [],
        products: [],
        clients: [],
        listClients: [],
        orders: [],
        sliders: [],
        complaints: [],
        complaintSolutions: [],
        news: [],
        clientTabs: [],

        // ============================
        users: [],
        authGroups: [],
        roles: [],
    },

    mutations: {},

    actions: {
        reset() {
            this.state.filter.search = "";
            this.state.chatId = "";

            this.state.productId = "";
            this.state.categoryId = "";
            this.state.catgId = "";
            this.state.clientId = "";
            this.state.newsTypeId = "";
            this.state.orderStatus = "";
            this.state.complaintType = "";
            this.state.duplicated = "";
            this.state.fromOffers = "";
            this.state.unReadMessages = "";
            this.state.isPending = "";
        },

        async getChats() {
            // console.log(this.state.chatUserId);
            this.state.loading = true;
            try {
                let url = `Chats/all?Search=${this.state.filter.search
                    }&UnReadMessages=${this.state.unReadMessages == null
                        ? ""
                        : this.state.unReadMessages
                    }&IsPending=${this.state.isPending == null ? "" : this.state.isPending
                    }`;
                if (this.state.chatUserId != "") {
                    url = url + `&userId=${this.state.chatUserId}`;
                }

                const res = await axios.get(url);

                if (res.data.result.length > 0)
                    this.state.chats = res.data.result;
                else this.state.chats = [];
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
                this.state.chatUserId = "";
            }
        },

        async getMessages() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Messages?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search}&chatId=${this.state.chatId}&IsDelete=false`
                );
                this.state.messages = res.data.result.data.reverse();
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getReadyResponses() {
            this.state.loading = true;
            try {
                const res = await axios.get(`ReadyResponses`);
                this.state.readyResponses = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getOffers() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Offers?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search}&productId=${this.state.productId}&IsDelete=false`
                );
                this.state.offers = res.data.result.data;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getSliders() {
            this.state.loading = true;
            try {
                const res = await axios.get(`Sliders`);
                this.state.sliders = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getNews() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `News?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize
                    }&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&IsDelete=false&NewsTypeId=${this.state.newsTypeId == null
                        ? ""
                        : this.state.newsTypeId
                    }`
                );
                this.state.news = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getComplaintSolutions() {
            this.state.loading = true;
            try {
                const res = await axios.get(`ComplaintSolutions`);
                this.state.complaintSolutions = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getComplaints() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Complaints?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&Type=${this.state.complaintType == null
                        ? ""
                        : this.state.complaintType
                    }&IsDelete=false`
                );
                this.state.complaints = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getRepeatedComplaints() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Complaints?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&Type=1&Duplicated=true&IsDelete=false`
                );
                this.state.complaints = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getCategories() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Categories?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&ParentId=${this.state.parentId == null ? "" : this.state.parentId
                    }&CategoryId=${this.state.catgId == null ? "" : this.state.catgId
                    }&IsDelete=false`
                );
                this.state.categories = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getListCategories() {
            this.state.loading = true;
            try {
                const res = await axios.get(`Categories/list?`);
                this.state.listCategories = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getProducts(context, { categoryId, index }) {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Products?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&CategoryId=${categoryId == null
                        ? ""
                        : categoryId
                    }&IsDelete=false`
                );
                this.state.products[index] = res.data.result.data;
                //  console.log(this.state.products[index]);

                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
                return res.data.result.data;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getClients() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Clients?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&ProvinceId=${this.state.provinceId == null
                        ? ""
                        : this.state.provinceId
                    }&Status=${this.state.orderStatus == null
                        ? ""
                        : this.state.orderStatus
                    }`
                );
                this.state.clients = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getListClients() {
            this.state.loading = true;
            try {
                const res = await axios.get(`Clients/list`);
                this.state.listClients = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        async getFavClients() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Clients?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&Rating=4`
                );
                this.state.clients = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getBlkClient() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Clients?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&IsBlock=true`
                );
                this.state.clients = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getNewClient() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Clients?PageIndex=${this.state.filter.pageIndex
                    }&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&IsNew=true`
                );
                this.state.clients = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getOrders() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Orders?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize
                    }&Search=${this.state.filter.search == null
                        ? ""
                        : this.state.filter.search
                    }&FromUserId=${this.state.clientId == null ? "" : this.state.clientId
                    }&ProvinceId=${this.state.provinceId == null
                        ? ""
                        : this.state.provinceId
                    }&Duplicated=${this.state.duplicated == null
                        ? ""
                        : this.state.duplicated
                    }&FromOffers=${this.state.fromOffers == null
                        ? ""
                        : this.state.fromOffers
                    }&Status=${this.state.orderStatus == null
                        ? ""
                        : this.state.orderStatus
                    }`
                );
                this.state.orders = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },

        async getClientTabs() {
            this.state.loading = true;
            try {
                const res = await axios.get(`ClientTabs`);
                this.state.clientTabs = res.data.result;
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.state.loading = false;
            }
        },

        // ------------------------------------ //
        async getUsers() {
            this.state.loading = true;
            try {
                const res = await axios.get(
                    `Users?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search}&IsDelete=false`
                );
                this.state.users = res.data.result.data;
                this.state.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(
                            res.data.result.count / this.state.filter.pageSize
                        );
            } catch (err) {
                // console.log(err);
                // service.faild(err.response.data.message);
            } finally {
                this.state.loading = false;
            }
        },

        async getAuthGroups() {
            this.state.loading = true;
            try {
                const res = await axios.get(`AuthGroups`);
                this.state.authGroups = res.data.result;
            } catch (err) {
                // console.log(err);
                // service.faild(err.response.data.message);
            } finally {
                this.state.loading = false;
            }
        },

        async getRoles() {
            this.state.loading = true;
            try {
                const res = await axios.get(`AuthGroups/roles`);
                this.state.roles = res.data.result;
            } catch (err) {
                // console.log(err);
            } finally {
                this.state.loading = false;
            }
        },
    },
    modules: {
        toastification,
    },
});

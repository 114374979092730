export default [
    {
        path: "/accounts",
        name: "الحسابات",
        component: () => import("@/views/accounts/accounts.vue"),
        meta: {
            single: "الحسابات",
            endPoint: "Categories",
            breadcrumbs: [{ text: "إدارة الحسابات", disabled: false }],
            requireAuth: true,
            auth: ["getCategories", "adminOffice"],
        },
    },
];

export const auth = {
    namespaced: true,
    state: {
        userData: JSON.parse(localStorage.getItem("userData")),
        accessToken: localStorage.getItem("accessToken"),
    },

    mutations: {
        authUser(state, item) {
            state.userData = item.userData;
            state.accessToken = item.accessToken;
        },

        logout(state) {
            // this.$messaging
            //     .unsubscribeFromTopic(localStorage.getItem("fmsToken"), "admin")
            //     .then(function (response) {
            //         // See the MessagingTopicManagementResponse reference documentation
            //         // for the contents of response.
            //         // console.log(
            //             "Successfully unsubscribed from topic:",
            //             response
            //         );
            //     })
            //     .catch(function (error) {
            //         // console.log("Error unsubscribing from topic:", error);
            //     });

            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("typeFont");
            localStorage.removeItem("fmsToken");
            state.token = null;
            state.user = null;
        },
    },

    actions: {
        login({ commit }) {
            commit("authUser", {
                userData: JSON.parse(localStorage.getItem("userData")),
                accessToken: localStorage.getItem("accessToken"),
            });
            // console.log("auth login");
        },

        logout({ commit }) {
            // console.log("logout");
            commit("logout");
        },
    },
};

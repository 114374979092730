import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import global from "../store/global";
import routesModel from "./routes";
import categories from "./routes/categories";
import products from "./routes/products";
import clients from "./routes/clients";
import complaint from "./routes/complaint";
import sliders from "./routes/slider";
import chats from "./routes/chats";
import offers from "./routes/offers";
import orders from "./routes/orders";
import news from "./routes/news";
import accounts from "./routes/accounts";

Vue.use(VueRouter);

const routes = [
    ...routesModel,
    ...categories,
    ...products,
    ...clients,
    ...chats,
    ...offers,
    ...orders,
    ...sliders,
    ...news,
    ...complaint,
    ...accounts,
];

const router = new VueRouter({
    mode: "history",
    // mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // title tap changer
    // window.document.title = to.name ? to.name : "Hotel";

    global.dispatch("reset");
    if (to.matched.some((r) => r.meta.requireAuth)) {
        if (
            store.state.auth.accessToken == undefined ||
            store.state.auth.accessToken == null
        ) {
            next("/login");
        }

        if (store.state.auth.userData.roles && to.meta.auth) {
            // // console.log(to.meta.auth);
            // // console.log(store.state.auth.userData.roles);

            for (var i = 0; i < to.meta.auth.length; i++) {
                if (store.state.auth.userData.roles.find((x) => x == to.meta.auth[i])) {
                    return next();
                }
            }
            next("/forbidden");
        }
    }
    next();
});

export default router;

export default [
    {
        path: "/chats/:id?",
        name: "المحادثات",
        component: () => import("@/views/chats/chats.vue"),
        meta: {
            single: "صنف",
            endPoint: "Chats",
            breadcrumbs: [{ text: "إدارة المحادثات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "chat"],
        },
    },

    {
        path: "/readyResponses",
        name: "الردود الجاهزة",
        component: () =>
            import("@/views/chats/readyResponses/readyResponses.vue"),
        meta: {
            single: "رد جاهز",
            endPoint: "ReadyResponses",
            breadcrumbs: [{ text: "الردود الجاهزة", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "settings"],
        },
    },
];

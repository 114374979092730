export const roles = {
    namespaced: true,
    state: {
        userData: JSON.parse(localStorage.getItem("userData")),
        accessToken: localStorage.getItem("accessToken"),
    },

    getters: {
        adminRole(state) {
            try {
                if (state.accessToken != null)
                    return state.userData.roles.includes("adminOffice");
            } catch (err) {
                return false;
            }
        },
    },

    mutations: {},

    actions: {},
};

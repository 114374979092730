export default [
    {
        path: "/slider",
        name: "الاعلانات",
        component: () => import("@/views/slider/sliders.vue"),
        meta: {
            single: "اعلان",
            endPoint: "Sliders",
            breadcrumbs: [{ text: "إدارة الاعلانات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "slider"],
        },
    },
];

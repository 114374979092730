export default [
    {
        path: "/complaint",
        name: "إدارة الشكاوي والمقترحات",
        component: () => import("@/views/complaints/complaint.vue"),
        meta: {
            single: "شكوى",
            endPoint: "Complaints",
            breadcrumbs: [{ text: "الشكاوي والمقترحات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "complaint"],
        },
    },
    {
        path: "/repeatedComplaints",
        name: "الشكاوي المكررة",
        component: () => import("@/views/complaints/repeatedComplaints.vue"),
        meta: {
            single: "شكوى",
            endPoint: "RepeatedComplaints",
            breadcrumbs: [{ text: "الشكاوي المكررة", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "complaint"],
        },
    },
    {
        path: "/allComplaint",
        name: " كل الشكاوي",
        component: () => import("@/views/complaints/allComplaint.vue"),
        meta: {
            single: "شكوى",
            endPoint: "Complaints",
            breadcrumbs: [{ text: "الشكاوي والمقترحات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "complaint"],
        },
    },
    {
        path: "/complaintsDetails",
        name: "complaintsDetails",
        component: () => import("@/views/complaints/complaintsDetails.vue"),
        meta: {
            endPoint: "News",
            breadcrumbs: [{ text: "تفاصيل", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "complaint"],
            props: true,
        },
    },
    {
        path: "/complaint-solution",
        name: " حلول الشكاوي",
        component: () =>
            import(
                "@/views/complaints/complaintSolution/complaintSolution.vue"
            ),
        meta: {
            single: "حل جاهز",
            endPoint: "ComplaintSolutions",
            breadcrumbs: [{ text: "حلول الشكاوي", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "settings"],
        },
    },
];

export default [
    {
        path: "/offers",
        name: "العروض",
        component: () => import("@/views/offers/offers.vue"),
        meta: {
            single: "عرض",
            endPoint: "Offers",
            breadcrumbs: [{ text: "إدارة العروض", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "offers"],
        },
    },
];

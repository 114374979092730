export default [
    {
        path: "/products",
        name: "المنتجات",
        component: () => import("@/views/warehouse/products/products.vue"),
        meta: {
            single: "منتج",
            endPoint: "Products",
            breadcrumbs: [{ text: "إدارة المنتجات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "category"],
        },
    },
    {
        path: "/productDetails",
        name: "productDetails",
        component: () =>
            import("@/views/warehouse/products/productDetails.vue"),
        meta: {
            single: "منتج",
            endPoint: "Products",
            breadcrumbs: [{ text: "إدارة المنتجات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "category"],
        },
    },
];
